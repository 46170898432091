import React from "react"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import LearnButton from "../components/mainButton"
import CTA from "../components/cta"
import Layout from "../components/layout"
import SEO from "../components/seo"

const PlumbingServices = () => {
  const data = useStaticQuery(graphql`
    query plumbingImages {
      faucet: file(relativePath: { eq: "faucet-repair-services.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      toilet: file(relativePath: { eq: "toilet-repair.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sink: file(relativePath: { eq: "sink-repair.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      drain: file(relativePath: { eq: "drain-repair.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      disposal: file(relativePath: { eq: "garbage-disposal-repair.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Plumbing Repairs"
        description={`Our experienced team can fix your home's faucets, drains, sinks, toilets, and more. We offer fast, exceptional service to the North Houston Community.`}
      />
      <section>
        <div className="py-16 px-2 md:px-0">
          <h1 className="text-4xl text-center font-semibold text-blue-400 pb-16">
            Plumbing Services
          </h1>
          <div>
            <div className="md:w-full md:mb-8 lg:mr-8">
              <div className="md:flex">
                <div className="md:w-1/2 lg:max-w-1/3 rounded overflow-hidden shadow-lg mb-8 md:mr-8 md:mb-0">
                  <Img
                    className="w-full"
                    fluid={data.faucet.childImageSharp.fluid}
                    alt="Faucet Repair"
                  />
                  <div className="px-3 py-4 md:px-6 md:py-8">
                    <h3 className="font-bold text-xl mb-4">Faucets</h3>
                    <p className="text-gray-600 text-base mb-4">
                      Whether your faucet is leaking, you need a new
                      installation, or your water is too hot, we can get it
                      fixed right away.
                    </p>
                    <LearnButton link={"/plumbing/faucets"} />
                  </div>
                </div>
                <div className="md:w-1/2 lg:max-w-1/3 rounded overflow-hidden shadow-lg mb-8 md:mr-8 md:mb-0">
                  <Img
                    className="w-full"
                    fluid={data.toilet.childImageSharp.fluid}
                    alt="Toilet Repair"
                  />
                  <div className="px-3 py-4 md:px-6 md:py-8">
                    <h3 className="font-bold text-xl mb-4">Toilets</h3>
                    <p className="text-gray-600 text-base mb-4">
                      We're experts on toilet maintenance and repair. We can
                      clear a clog, reset seals, repair leaks, and more.
                    </p>
                    <LearnButton link={"/plumbing/toilets"} />
                  </div>
                </div>
                <div className="md:w-1/2 lg:max-w-1/3 rounded overflow-hidden shadow-lg mb-8 md:mb-0">
                  <Img
                    className="w-full"
                    fluid={data.sink.childImageSharp.fluid}
                    alt="Sink Repair"
                  />
                  <div className="px-3 py-4 md:px-6 md:py-8">
                    <h3 className="font-bold text-xl mb-4">Sinks</h3>
                    <p className="text-gray-600 text-base mb-4">
                      We work on all kinds of kitchen and bathroom sinks. If
                      you're having issues with your drain, stops, or strainer,
                      we're the right plumbers for the job.
                    </p>
                    <LearnButton link={"/plumbing/sinks"} />
                  </div>
                </div>
              </div>
            </div>
            <div className="md:w-full">
              <div className="md:flex md:mr-16">
                <div className="md:w-1/3 rounded overflow-hidden shadow-lg mb-8 md:mr-8 md:mb-0">
                  <Img
                    className="w-full"
                    fluid={data.drain.childImageSharp.fluid}
                    alt="Drain Repair"
                  />
                  <div className="px-3 py-4 md:px-6 md:py-8">
                    <h3 className="font-bold text-xl mb-4">Drains</h3>
                    <p className="text-gray-600 text-base mb-4">
                      We clear sink, shower, bathtub, wash machine, and sewer
                      line drains right the first time so you don't have to
                      worry.
                    </p>
                    <LearnButton link={"/plumbing/drains"} />
                  </div>
                </div>
                <div className="md:w-1/3 rounded overflow-hidden shadow-lg mb-8 md:mb-0">
                  <Img
                    className="w-full"
                    fluid={data.disposal.childImageSharp.fluid}
                    alt="Garbage Disposal Repair"
                  />
                  <div className="px-3 py-4 md:px-6 md:py-8">
                    <h3 className="font-bold text-xl mb-4">Disposals</h3>
                    <p className="text-gray-600 text-base mb-4">
                      Is your disposal broken or jammed? We work on clearing,
                      repairing, and replacing this essential tool in your
                      kitchen.
                    </p>
                    <LearnButton link={"/plumbing/disposals"} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CTA />
    </Layout>
  )
}

export default PlumbingServices
